import React from "react"
import { Link } from "gatsby"
import { Box, Button, Heading, Paragraph,Text } from "grommet"
import { Linkedin, Twitter } from "grommet-icons"

import Seo from "../components/seo"
import Layout from "../components/layout"
import SignUp from "../components/signup"

export default function Manifesto() {
  return (
    <Layout themeMode="dark">
      <Seo title="Manifesto" />
      <Box>
        <Box
          width="xlarge"
          alignSelf="center"
          background="white"
          round={{ corner: "top", size: "xxsmall" }}
          pad="medium"
          margin={{ top: "large" }}
        >
          <Box width="large" margin={{ bottom: "large" }} alignSelf="center">
            <Heading
              className="hero"
              margin={{ top: "xlarge", bottom: "xsmall" }}
              level="2"
              size="xlarge"
            >
              Sign up for purpose
            </Heading>
            <Paragraph fill size="xlarge">
              The constant striving for growth by the global business community
              and governments the world over is not sustainable.
            </Paragraph>
            <Paragraph fill size="large">
              We will surpass the finite resources of the world we live in.
              There has to be a radical re-design of the way we do business — we
              can rebuild our economies to be better, to be measured and driven
              by something more meaningful while respecting the boundaries of
              our global ecosystem.
            </Paragraph>
            <Paragraph fill size="large">
              We can build businesses that meet the needs of the individual,
              achieve commercial success and create a sustainable market economy
              for all, for the long-term. These things are not mutually
              exclusive, they're interdependent.
            </Paragraph>
            <Paragraph fill size="large">
              Designing for purpose gives employees drive, autonomy and
              productivity when things are tough — like when you're suddenly a
              remote workforce and a reason to ‘go to work’ everyday is
              paramount. Purpose gives customers a reason to talk to you and
              stay with you when options and budgets are limited. Purpose
              ensures the future economy works for the betterment of society by
              designing positive outcomes into the business model.
            </Paragraph>
            <Paragraph fill size="large">
              There's huge opportunity for leaders and entrepreneurs in
              designing for purpose. It's crazy that the majority of businesses
              aim to achieve only growth, often at the expense of everything and
              everyone else. Focus on purpose and growth is both organic and
              sustainable.
            </Paragraph>
            <Paragraph fill size="large">
              This is the future of business design, where there is no need to
              choose between people, profit and the benefit of wider society,
              because it achieves all three and is stronger for it.
            </Paragraph>
            <Heading size="medium" level="3" margin={{ bottom: "small" }}>
              What are we doing?
            </Heading>
            <Paragraph fill size="large">
              We’re delivering rapid business design for our clients, developing
              products that enable autonomous product teams and writing a
              'how-to' on the way we do it. Then we're giving the book away, so
              anyone can use it as a springboard for change.
            </Paragraph>
            <Box
              margin={{ vertical: "small" }}
              border={{ size: "small", side: "all", color: "brand" }}
              pad="medium"
            >
              <Paragraph size="large">
                Get a copy of the book for free by subscribing to the Po3
                newsletter
              </Paragraph>
              <SignUp />
              <Paragraph fill>
                Subscribers get one article a week and a free copy of the book.
                A 'how to' on purpose driven design. To give you an idea of
                what's coming, we'll immediately send you a copy of
                Econsultancy's Best Practice Guide to Rapid Innovation (which we
                authored at a previous consultancy).
              </Paragraph>
            </Box>
            <Paragraph fill size="large">
              To keep up momentum we'll serialise the new book, creating one
              article a week. Helping to create structure and giving you an
              interesting read. At the end we'll publish as a clear, curated and
              straightforward manual, for anyone who wants to put purpose at the
              centre of: transformation, innovation, market disruption -
              wherever you are in your venture.
            </Paragraph>

            <Heading size="medium" level="3" margin={{ bottom: "small" }}>
              Why are we doing it?
            </Heading>
            <Paragraph fill size="large">
              We want to see a better socio-economic future beyond what exists
              and this is how we can contribute. Purpose driven business design
              shouldn't be complex, confusing or exclusive. The more people that
              know how to do it the better, because it builds a better economy.
            </Paragraph>
            <Paragraph fill size="large">
              If we change the way we do business it has the power to re-build
              economies from the grass roots. Everyone wants to succeed. When
              people see others doing well, they adapt those successful
              behaviours to achieve the same outcome. Showing that purpose
              driven businesses are not only ethical but also market leading
              will be a catalyst for progressive change.
            </Paragraph>
            <Heading size="medium" level="3" margin={{ bottom: "small" }}>
              Be a part of the community
            </Heading>
            <Paragraph fill size="large">
              We'd love to be part of a community that wants to achieve the same
              things. A sustainable society where everyone finds purpose in
              their work and business has a positive impact in the world. Doing
              this gives us the chance to connect with like-minded people and
              maybe build something amazing from the conversations that happen.
            </Paragraph>
            <Paragraph fill size="large">
              If you want to get involved and radically redesign business for
              the better, or simply want to bounce ideas around — get in touch.
            </Paragraph>
            <Paragraph fill size="large">
              Contact details and an invite to the Po3 Slack community are in
              the welcome email on <Link to="/signup">sign-up</Link>.
            </Paragraph>
            <Paragraph fill size="large">
              Thanks for reading.
              <br />
              Kieran &amp; Phil
            </Paragraph>
            <Text size="large">
              <Button
                target="_blank"
                href="https://twitter.com/intent/tweet?text=Sign-up%20for%20purpose:%20https://po3.ventures/sign-up-for-purpose"
                margin={{ right: "medium", bottom: "medium" }}
                size="medium"
                color="brand"
                icon={<Twitter />}
                label="Share on Twitter"
              />
              <Button
                target="_blank"
                href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fpo3.ventures%2Fsign-up-for-purpose"
                size="medium"
                color="brand"
                icon={<Linkedin />}
                label="Share on LinkedIn"
              />
            </Text>
          </Box>
        </Box>
        <Box
        width="xlarge"
        alignSelf="center"
          background="light-1"
          round={{ corner: "bottom", size: "xxsmall" }}
          pad="medium"
        >
          <Box pad={{ vertical: "large" }} width="large" alignSelf="center">
            <Heading size="small" level={4}>
              Sign-up to get one article a week, a free book on purpose driven
              design + Econsultancy's Best Practice Guide to Innovation
            </Heading>
            <SignUp />
            <Paragraph>
              We will never use your email for anything other than to send you
              free articles written by us.
              <br />
              We're people, not a data hungry machine.
            </Paragraph>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}
